/* General Styles */
body {
    font-family: 'Open Sans', sans-serif;
    line-height: 1.7;
    background-color: #f8f9fa;
    color: #333;
    padding: 20px;
  }
  
  h2.title {
    font-size: 2.2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
    color: #0056b3;
  }
  
  h3 {
    color: #343a40;
    font-size: 1.8rem;
    margin-top: 25px;
    margin-bottom: 15px;
  }
  
  p.details {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    font-size: 1.1rem;
    color: #555;
    margin-bottom: 20px;
  }
  
  p.details strong {
    color: #0056b3;
  }
  
  table {
    width: 100%;
    margin: 20px 0;
    border-collapse: collapse;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  }
  
  table th, table td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #dee2e6;
  }
  
  table th {
    background-color: #0056b3;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  table tbody tr:nth-child(odd) {
    background-color: #f2f2f2;
  }
  
  table tbody tr:hover {
    background-color: #e9ecef;
  }
  
  /* Google AdSense Placeholder */
  .adsense-placeholder {
    margin: 30px 0;
    text-align: center;
    background-color: #f8f9fa;
    padding: 20px;
    border: 1px dashed #ccc;
    font-size: 1.2rem;
    color: #666;
  }
  
  .adsense-placeholder::before {
    content: 'Your Ad Here';
    font-style: italic;
    color: #888;
  }
  
  /* Disclaimer */
  h3 + p {
    font-style: italic;
    font-size: 0.95rem;
    color: #777;
    padding: 15px;
    border-left: 4px solid #ffc107;
    background-color: #fffbe6;
  }
  
  /* Buttons and Links */
  a {
    color: #0056b3;
    text-decoration: none;
    font-weight: 600;
  }
  
  a:hover {
    color: #ffc107;
  }
  
  /* Footer and Header (optional) */
  footer, header {
    background-color: #343a40;
    color: white;
    padding: 20px 0;
    text-align: center;
  }
  
  footer p, header p {
    margin: 0;
    font-size: 0.9rem;
  }
  
  footer a {
    color: #ffc107;
  }
  