 .swing{
    color: red;
    background-color: azure;
    font-size:larger;
    font-style: italic;
    text-decoration: none;
    
}
 
.li{
    color: rgb(35, 10, 0);
    border-bottom: 1px solid black;
    text-decoration: none;
    padding: 10px;
    
}
