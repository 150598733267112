
/* Default styles */
.header {
   color:rgb(241, 232, 232);
  
  padding: 1cm;
  font-size: large;
 
}

.h {
  background:rgb(35, 4, 106);
  text-align: center; 
 
}

@media only screen and (max-width: 950px) {
  .header {
   color:rgb(241, 232, 232);
   padding: 3px;
   font-size: large;
  
 }
 
}