.footer{
    color: rgb(222, 244, 189);
    text-align: center;
    background-color: rgb(35, 4, 106);
    font-size: large;
    padding: 0.5%;
    position: fixed;
    bottom: 0;
    
    left: 50%; /* Set left to 50% */
    transform: translateX(-50%); /* Move the element back by 50% of its own width */
    width: 100%;
    
}