
.live{
    color: aliceblue;
    background: red;
    border: 2 px solid rgb(14, 11, 11);
    border-radius: 10%;
    font-size: large;
    padding: 5px;
    transition: color 1s;

    
} 
.live {
    color: blue; /* Color to change to on hover */
}

.listing{
    margin: 20px;
    padding: 10px;
    /* border-left: 1px solid black; */
}
ul{
    margin-left: 25px;
    border-left: 2px solid red;
}

.heading{
    text-align: center;
}